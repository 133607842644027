import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./style.css";

const Footer = ({ t }) => {
  const NewsletterHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="footer-area">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 order-lg-1 order-1">
              <div className="single-footer">
                <ul className="footer-contact">
                  <li>
                    <i className="fas fa-phone-square-alt" />514 927-1549
                  </li>
                  <li>
                    <i className="fas fa-envelope" /> dominic@nordoconstruction.ca<br />
                    <i className="fas fa-envelope" /> simon@nordoconstruction.ca
                  </li>
                  <li>
                    <i className="fas fa-map" />8220 rue Pascal-Gagnon,<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Saint-Léonard (QC)<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;H1P 1Y4
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 order-lg-1 order-1"></div>
            <div className="col-lg-4 col-sm-6 order-lg-1 order-1">
              <div className="single-footer">
                <p className="footer-rbq">
                  Licence RBQ : 5823-7397-01
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="fade_rule" />
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>&copy; { t("footer.copyright") } 2023 | LES CONSTRUCTIONS NORDO INC.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default withTranslation()(Footer);
