import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from 'axios'

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    error: {},
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  redirect = () => {
    window.location.href = '/successcontact'
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { name, email, subject, message, error } = this.state;

    if (name === "") {
      error.name = "Please enter your name";
      return;
    }
    if (email === "") {
      error.email = "Please enter your email";
      return;
    }
    if (subject === "") {
      error.subject = "Please enter your subject";
      return;
    }
    if (message === "") {
      error.message = "Select your event list";
      return;
    }

    const data = {
      name,
      email,
      subject,
      message
    }

    axios({
      method: 'post',
      url: '/api/submit',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      setTimeout(this.redirect, 2000);
    }).catch((e) => {
      this.setState({
        error,
      });
    })
  };

  render() {
    const { name, email, subject, error, message } = this.state;

    return (
      <form onSubmit={this.submitHandler} className="form" >
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="form-field">
              <input
                value={name}
                onChange={this.changeHandler}
                type="text"
                name="name"
                placeholder={this.props.t("Contact.name")}
                required
              />
              <p>{error.name ? error.name : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="form-field">
              <input
                onChange={this.changeHandler}
                value={email}
                type="email"
                name="email"
                placeholder={this.props.t("Contact.email")}
                required
              />
              <p>{error.email ? error.email : ""}</p>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12">
            <div className="form-field">
              <input
                onChange={this.changeHandler}
                value={subject}
                type="text"
                name="subject"
                placeholder={this.props.t("Contact.subject")}
                required
              />
              <p>{error.subject ? error.subject : ""}</p>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12">
            <div className="form-field">
              <textarea
                name="message"
                value={message}
                onChange={this.changeHandler}
                placeholder={this.props.t("Contact.message")}
                required>
              </textarea>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="contact-form-action">
              <button className="form-button" type="submit" onClick={(e) => this.submitHandler(e)}>{this.props.t("Contact.send")}</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default withTranslation()(ContactForm);
