import React, {Fragment} from 'react';
import Header from '../../components/header'
import Hero from '../../components/hero'
import About from '../../components/about'
import ProjectSection from '../../components/ProjectSection'
import Footer from '../../components/footer'

const HomePage =() => {
    return(
        <Fragment>
            <Header/>
            <Hero/>
            <About/>
            <ProjectSection/>
            <Footer/>
        </Fragment>
    )
};
export default HomePage;

